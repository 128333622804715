.onboarding {
  &-title {
    color: #3cc4cb;
    font-weight: 400;
  }

  &-img-wrapper {
    display: flex;
    flex: 1 1 auto;
    background: radial-gradient(ellipse at center, #3cc4cb 0%, #32b7be 100%);

    img {
      width: 100%;
      height: auto;
    }
  }

  .vl-grid {
    margin-left: 0;
  }
  .vl-slider__frame {
    padding: 0;
    &.onboarding-description {
      padding: 1rem 1.5rem;
    }
    .vl-slide {
      padding: 0 1.5rem;
    }
  }
}
