.profile-contractList-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  .vl-info-tile__header {
    align-items: center;
    flex-grow: 0;
    flex: 0 1 auto;
  }

  .vl-icon {
    font-size: 1.3em;
  }

  .vl-info-tile__content {
    margin-top: 0.2rem;
    display: flex;
    flex: 1 1 auto;

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .vl-link {
    margin-top: 1.5rem;
  }

  &--active {
    border-color: #32b7be;
    border-width: 0.2rem;

    .vl-icon {
      color: #32b7be;
      font-size: 1.3em;
    }

    .vl-info-tile__content__wrapper {
      justify-content: flex-start;
    }
  }
}
